import { useLocation } from 'react-router-dom';
import { useMemo, useEffect, useState } from 'react'
import axios from 'axios';

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Redirect = () => {
  const [error, setError] = useState<boolean>(false);

  const query = useQuery();
  const url = query.get("url") || null

  useEffect(() => {
    if (url) {
      setTimeout(() => {
        window.location.assign(url)
    }, 300);
    } else {
      setError(true)
    }
  }, [])

  return (
    <div>
      {
        url ? 
        <div className="flex flex-col space-x-1 justify-center items-center">
              <span>
                  You are redirecting to myAvos, App should be launched automatically
              </span>
              <span>click this <a className='underline' style={{ color: '#38bdf8' }} href={url}>link</a> to launch the app manually. </span>
          </div>
        : <div> Error in redirecting</div>
      }
    </div>
  );
}

export default Redirect;