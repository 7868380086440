import { Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import Withings  from './pages/withings';
import Redirect  from './pages/redirect';
import Garmin from './pages/garmin';
import FitBit from './pages/fitbit';

export default (
    <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/withings' element={<Withings/>} />
        <Route path='/redirect' element={<Redirect/>} />
        <Route path='/garmin' element={<Garmin/>} />
        <Route path='/fitbit' element={<FitBit/>}/>
        <Route path='/*' element={<Home/>}/>
    </Routes>
)