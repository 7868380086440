import { useEffect } from 'react';

const Home = () => {
    useEffect(() => {
        setTimeout(() => {
            window.location.href = "https://optichronix.com/";
        }, 3000)
      }, []);

    return (
        <div>
            Oops! you have landed on a wrong page.
        </div>
    )   
}

export default Home;